<div cdkDropList class="{{items.length > 0 ? 'example-list list-group-flush' : ''}}" (cdkDropListDropped)="drop($event)">
    <div class="example-box" *ngFor="let item of items; index as i" cdkDrag [cdkDragData]="item" cdkDragBoundary=".example-list">
        <div class="mr-3 align-middle">
            <i class="fa fa-grip-vertical drag-handle" aria-hidden="true" cdkDragHandle></i>
        </div>

        <ng-container  style="display: inline-block" [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item, idx: i }"></ng-container>

        <div class="align-middle" style="padding-top: 40px">
            <label for="reorder-{{i}}" class="sr-only">Reorder</label>
                    <input *ngIf="accessibilityMode" id="reorder-{{i}}" type="number" min="0" [max]="items.length - 1" [value]="i" style="width: 40px" (focusout)="updateIndex(i, item)" (keydown.enter)="updateIndex(i, item)" aria-describedby="instructions">
        </div>
        <button class="btn btn-icon pull-right" (click)="removeItem(item, i)">
            <i class="fa fa-times" aria-hidden="true"></i>
            <span class="sr-only" attr.aria-labelledby="item.id--{{i}}">Remove item</span>
        </button>
        
    </div>
</div>

<p class="sr-only" id="instructions">

</p>