<ng-container *ngIf="data !== undefined">
    <div class="card" style="width: 18rem;">
        <div class="overlay" (click)="handleClick()">
          <i class="fa {{icon}} card-img-top text-center" aria-hidden="true"></i>
          <div class="card-actions">
            <app-card-actionables [actions]="actions" [labelBy]="data.name" iconClass="fa-ellipsis-v" (actionHandler)="performAction($event)"></app-card-actionables>
          </div>
        </div>
        <div class="card-body text-center" *ngIf="data.name.length > 0 || actions.length > 0">
            <span class="card-data.name" (click)="handleClick()">
              {{data.name}}
            </span>
        </div>
    </div>
</ng-container>