<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{title}} Bookmarks</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    
    <ul class="list-unstyled">
        <li class="list-group-item" *ngIf="bookmarks.length > 0">
            There are {{bookmarks.length}} pages bookmarked over {{uniqueChapters}} files.
        </li>
        <li class="list-group-item" *ngIf="bookmarks.length === 0">
            No bookmarks yet
        </li>
    </ul>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="clearBookmarks()" [disabled]="(isDownloading || isClearing) || bookmarks.length === 0">
        <span *ngIf="isClearing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span>Clear{{isClearing ? 'ing...' : ''}}</span>
    </button>
    <button type="button" class="btn btn-secondary" (click)="downloadBookmarks()" [disabled]="(isDownloading || isClearing) || bookmarks.length === 0">
        <span *ngIf="isDownloading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span>Download{{isDownloading ? 'ing...' : ''}}</span>
    </button>
    <button type="button" class="btn btn-primary" (click)="close()">Close</button>
</div>
