<app-bulk-operations [actionCallback]="bulkActionCallback"></app-bulk-operations>
<app-card-detail-layout header="{{libraryName}}" 
    [isLoading]="loadingSeries"
    [items]="series"
    [actions]="actions"
    [pagination]="pagination"
    [filters]="filters"
    (applyFilter)="updateFilter($event)"
    (pageChange)="onPageChange($event)"
    >
    <ng-template #cardItem let-item let-position="idx">
        <app-series-card [data]="item" [libraryId]="libraryId" [suppressLibraryLink]="true" (reload)="loadPage()" (selection)="bulkSelectionService.handleCardSelection('series', position, series.length, $event)" [selected]="bulkSelectionService.isCardSelected('series', position)" [allowSelection]="true"></app-series-card>
    </ng-template>
</app-card-detail-layout>
